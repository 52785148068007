import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day.`}</p>
    <p><strong parentName="p">{`*`}{`Today is the final day of the 2017 CrossFit Games. We are having a
party at the Barnett’s house today from 12-6 so please stop by if you
can make it. We’ll eat, watch the Games, and swim if you’d like. This is
a family friendly event so bring the kids! Please bring a side dish (if
your last name starts with A-M) or dessert (if your last name starts
with N-Z). We’ll supply the meat and drinks. Call or text Daniel for
directions 502-235-4545.  Sunday’s 12:30 class will be cancelled for the
party.`}{`*`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      